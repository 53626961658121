import React from 'react';
import MaterialTable from "material-table";

const Tables = ({columns, data, title, options, localization, actions}) => {
    return (
        <div style={{ maxWidth: "100%" }}>
            <MaterialTable
                title={title}
                columns={columns}
                data={data}
                options={options}
                localization={localization}
                actions={actions}
            />
        </div>
    );
}

export default Tables;