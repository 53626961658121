import React, { useState, useEffect } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import axios from 'axios';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AppsIcon from '@material-ui/icons/Apps';

import Menu from './components/Menu';
import Copyright from './components/Copyright';

import Index from './views/Index';
import NoMatch from './views/NoMatch';
import Settings from './views/Settings';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: '#FF671B'
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  dialogContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dialogFormControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
}));

const MView = ({clearStorage}) => {
  const { path } = useRouteMatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [raffles, setRaffles] = useState([]);
  const [raffle, setRaffle] = useState(null);
  const [bases, setBases] = useState(null);
  const [menuTitle, setMenuTitle] = useState('Inicio');
  const [name, setName] = useState('');
  const [dataTickets, setDataTickets] = useState([{
    id:         null,
    tickets:    null,
    scrstring:  null,
    date:       null
  }]);
  const [dataTickets2, setDataTickets2] = useState([{
    scrstring: null,
    date: null,
    id: null
  }]);
  var rfl = [];

  useEffect(() => {
    getRaffles();
  }, []);

  const getRaffles = (call) => {
    axios.post(`${process.env.REACT_APP_APIUSER_INDEX}/getRaffles`, null,
    {
      headers: {
        token: localStorage.getItem('token'),
      }
    }).then(response => {
      setName(response.data.name);
      if(!response.data.err) {
        if (response.data.msg !== 'usuario nuevo') {
          response.data.result.map((r, i) => {
            rfl.push(r);
            return rfl;
          });
        }
      } else if (response.data.err) {
        history.push('/mv/settings');
      }
    }).then(() => {
        if(rfl.length > 0) {
          setRaffles(rfl);
          if (call) {
            setRaffle(call);
            getData(call);
            getDataTickets(call);
            getBases(call);
          } else {
            setRaffle(rfl[0].id);
            getData(rfl[0].id);
            getDataTickets(rfl[0].id);
            getBases(rfl[0].id);
          }
        } else {
          setDataTickets([]);
        }
    }).catch(err => console.error(err));
  }

  const getBases = (id) => {
    axios.post(`${process.env.REACT_APP_APIUSER_INDEX}/getBases`, {
      id: id
    },{
      headers: {token: localStorage.getItem('token')}
    }).then(response => {
      setBases(response.data);
    }).catch(err => console.error(err));
  }

  const getData = val => {
    axios.post(`${process.env.REACT_APP_APIUSER_INDEX}/getData`, {
      id: val
    },{
      headers: {
        token: localStorage.getItem('token'),
      }
    }).then(response => {
      setDataTickets(response.data);
    }).catch(err => console.error(err));
  }

  const getDataTickets = (rifa) => {
    axios.post(`${process.env.REACT_APP_APIUSER_INDEX}/getDataTickets`, {
        idRaffle: rifa
    },{
        headers: { token: localStorage.getItem('token') }
    }).then(response => {
        setDataTickets2(response.data);
    }).catch(err => console.error(err));
  }

  let history = useHistory();
  let LogOut = () => {
    history.push('/');
    clearStorage();
  }

  const handleChange = event => {
    setRaffle (event.target.value);
    getData(event.target.value);
    getDataTickets(event.target.value);
    getBases(event.target.value);
  };

  const handleClickOpen = () => {
    setOpenSelect(true);
  };

  const handleClose = () => {
    setOpenSelect(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {menuTitle}
          </Typography>
          {raffles.length > 1 ?
            <Button color="inherit" onClick={handleClickOpen}>
              <AppsIcon/>
            </Button>
          : null}
          <IconButton color="inherit" onClick={() => LogOut()}>
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <Menu setMenuTitle={setMenuTitle}/>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          {/* Here comes the magic */}
            <Switch>
              <Route exact path={path}>
                <Index dataTickets={dataTickets} dataTickets2={dataTickets2} raffle={raffle ? raffles[raffle-1] : null} bases={bases} getData={getData} setRaffle={setRaffle} getRaffles={getRaffles} name={name} />
              </Route>
              <Route path={`${path}/settings`}>
                <Settings />
              </Route>
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
      </main>
      <Dialog disableBackdropClick disableEscapeKeyDown open={openSelect} onClose={handleClose}>
        <DialogTitle>Selecciona tu sorteo</DialogTitle>
        <DialogContent>
          <form className={classes.dialogContainer}>
            <FormControl className={classes.dialogFormControl} fullWidth>
              <InputLabel id="demo-dialog-select-label">Sorteo</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={raffle}
                onChange={handleChange}
                input={<Input />}
              >
                {raffles.map((r, i) => {
                  return(
                    <MenuItem value={r.id} key={i}>{r.name}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MView;