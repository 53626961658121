import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// Start Material-ui Imports Styles
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
// End Material-ui Imports Styles

const useStyles = makeStyles({
  card: {
    minWidth: 150,
  },
  title: {
    fontSize: 14,
  }
});

const DashCard = ({title, content}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h5" component="h2">
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default DashCard;