import React, { useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
// Start Material-ui Imports Styles
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
// End Material-ui Imports Styles
// Start Material-ui Imports Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// End Material-ui Imports Icons

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(2)
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  }
}));

// var count = 0;

const Add = ({bases}) => {
  console.log(bases ? bases : 'no hay bases');
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        title={bases ? bases[0].base : null}
        subheader={bases ? moment(bases[1].base).format('LL') : null}
      />
      <CardMedia
        className={classes.media}
        image={ require(`../images/${bases ? bases[2].base : 'default.png'}`) }
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {bases ? bases[3].base : null}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {bases ? bases.map((base, i) => {
            if (i > 3) {
              console.log(i);
              return(
                <Typography key={i} style={{fontSize: '1vh'}}>
                  {base ? i-3 + ' - ' + base.base : null}
                </Typography>
              )
            } else {
              return(<div key={i}></div>)
            }
          }):null}
          
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default Add;