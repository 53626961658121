import React, { useState } from 'react';
import axios from 'axios';
// Start Material-ui Imports Styles
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
// End Material-ui Imports Styles

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      width: '100%',
    },
    seccion: {
        padding: theme.spacing(2)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        padding: theme.spacing(3),
    },
  }));

const Security = () => {
    const classes = useStyles();
    const [pass1, setPass1] = useState('');
    const [pass2, setPass2] = useState('');
    const [ePass1, setEPass1] = useState(false);
    const [ePass2, setEPass2] = useState(false);

    const updatePassword = e => {
        e.preventDefault();
        if(!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(pass1))) { setEPass1(true) } else { setEPass1(false) };
        if(pass1 !== pass2) { setEPass2(true) } else { setEPass2(false) };
        if(!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(pass1)) || pass1 !== pass2) {
            return null;
        } else {
            axios.post(`${process.env.REACT_APP_APIUSER_CRUD}/updatePassword`, {
                password: pass1
            },{
                headers: { token: localStorage.getItem('token') }
            }).then(response => {
                alert(response.data);
                setPass1('');
                setPass2('');
            }).catch(err => console.error(err));
        }
    }

    return (
        <div>
            <Paper className={classes.root}>
                <div className={classes.seccion}>
                    <Typography variant='h6'>Cambiar contraseña</Typography>
                </div>
                <Divider/>
                <div className={classes.seccion}>
                    <form className={classes.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    autoComplete="password"
                                    name="password"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    type="password"
                                    id="password1"
                                    label="nueva contraseña"
                                    autoFocus
                                    onChange={e => setPass1(e.target.value)}
                                    value={pass1}
                                    error={ePass1}
                                    helperText={ePass1 ? 'Mínimo 8 caracteres, al menos 1 mayúscula, 1 minúscula y 1 número' : null}
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    autoComplete="password"
                                    name="password"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    type="password"
                                    id="password2"
                                    label="verificar constraseña"
                                    onChange={e => setPass2(e.target.value)}
                                    value={pass2}
                                    error={ePass2}
                                    helperText={ePass2 ? 'Las contraseñas no coinciden' : null}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Divider/>
                <div className={classes.seccion}>
                    <Button variant="contained" color="primary" type="submit" onClick={updatePassword}>Guardar cambios</Button>
                </div>
            </Paper>
        </div>
    );
}

export default Security;