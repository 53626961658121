import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline, Typography, Button, List, ListItem, ListItemIcon, ListItemText, Link } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import myLogo from '../images/logo.png';
import Footer from '../components/Copyright';

const useStyles = makeStyles(theme => ({
    paragraph: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    space: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
    },
    bold: {
        fontWeight: "bold"
    },
    indentation: {
        paddingLeft: "30px"
    }
}));

const Privacy = () => {
    let history = useHistory();
    const classes = useStyles();
    return (
        <React.Fragment>
            <div style={{display: "flex", height: "10vh", minHeight: "50px", justifyContent: "space-between", padding: "1.5vh", maxWidth: "100%"}}>
                <div style={{display: "flex", justifyContent: "center", paddingLeft: "2vh"}}><img src={myLogo} style={{maxWidth: "300px", height: "auto"}}></img></div>
                <div style={{display: "flex", justifyContent: "center", paddingRight: "2vh"}}>
                    <Button onClick={() => {history.push('/termsofservice')}}>Terminos y Condiciones</Button>
                    <Button onClick={() => {history.push('/')}}>Inicio</Button>
                </div>
            </div>
            <hr />
            <Container component="main" maxWidth="lg">
                <CssBaseline />
                <Typography variant="h2">Aviso de Privacidad</Typography>
                <Typography variant="body1" className={classes.paragraph}>
                    Sistemas de Ingeniería y Control RC S.A. de C.V., (en lo sucesivo “ControlES”) con domicilio en Calle Río Bravi No. 1427, colonia Quinta Velarde, C.P. 44430, Guadalajara, Jalisco, es responsable del tratamiento y protección de sus datos personales. Para tales efectos comunica lo siguiente:
                </Typography>
                <Typography variant="subtitle1" className={classes.bold}>Datos de Contacto</Typography>
                <List>
                    <Link href="tel:+523336192370">
                        <ListItem button>
                            <ListItemIcon>
                                <PhoneIcon />
                            </ListItemIcon>
                                <ListItemText primary="+52 (33) 3619 2370"/>
                        </ListItem>
                    </Link>
                    <Link href="https://wa.me/523314152966?text=Contacto%20aviso%20de%20privacidad%20sorteos%20www.cargaygana.com.mx">
                        <ListItem button>
                            <ListItemIcon>
                                <WhatsAppIcon />
                            </ListItemIcon>
                            <ListItemText primary="+52 (33) 1415 2966"/>
                        </ListItem>
                    </Link>
                    <Link href="mailto:info@corporativorc.com">
                        <ListItem button>
                            <ListItemIcon>
                                <AlternateEmailIcon />
                            </ListItemIcon>
                                <ListItemText primary="info@corporativorc.com" />
                        </ListItem>
                    </Link>
                </List>
                <Typography variant="body1" className={classes.paragraph}>
                    Los datos personales recabados son utilizados para la siguiente finalidad primaria, que es necesaria para proveer el servicio que usted solicita:
                </Typography>
                <Typography variant="body1" className={classes.space}>
                    <ul className={classes.indentation}>
                        <li>Emitir boletos para rifas correspondientes a su consumo en la estación de servicio.</li>
                        <li>Disponibilidad de la información para gestionar y selecciónar el ganador de los sorteos.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                    Adicionalmente, su información personal puede ser utilizada para las siguientes finalidades secundarias que no son necesarias para el servicio solicitado, pero nos permiten brindarle un servicio ágil y mejor atención:
                </Typography>
                <Typography variant="body1" className={classes.space}>
                    <ul className={classes.indentation}>
                        <li>Disponibilidad de la información para la cange de boletos en otras Estaciones de Servicio con el Sistema.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                    Con el objetivo de llevar a cabo las finalidades descritas en el presente aviso, se utilizan los siguientes datos personales:
                </Typography>
                <Typography variant="body1" className={classes.space}>
                    <ul className={classes.indentation}>
                        <li>Nombre completo</li>
                        <li>Domicilio</li>
                        <li>Correo electronico</li>
                        <li>Télefono</li>
                    </ul>
                </Typography>
                <Typography variant="caption" className={classes.indentation}>
                    Para realizar las finalidades expresadas en este aviso de privacidad no se utilizan datos personales considerados como sensibles.
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                    Sus datos personales podrán ser transferidos a terceros dentro y fuera del país en los siguientes supuestos:
                </Typography>
                <Typography variant="body1" className={classes.space}>
                    <ul className={classes.indentation}>
                        <li>Para poder almacenar sus datos personales, éstos podrán ser transferidos a terceros distintos del responsable los cuales tendrán la calidad de encargados en términos de ley.</li>
                        <li>Para efectos de dar cumplimiento a las leyes de gobernación aplicables al área de la Dirección General de Juegos y Sorteos.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                    En caso de no estar de acuerdo con la transferencia de datos que requieran su consentimiento podrá oponerse a dicho tratamiento a través de los medios referidos en el presente aviso.
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                    Para llevar a cabo la transferencia de sus datos personales requerimos obtener su consentimiento. Si usted no manifiesta su negativa para la realización de dichas transferencias, se dará por entendido que ha dado su consentimiento.
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                    No autorizo que mis datos personales sean compartidos con los siguientes terceros:
                </Typography>
                <Typography variant="body1" className={classes.space}>
                    <ul className={classes.indentation}>
                        <li>DigitalOcean&trade; Inc.</li>
                        <li>Corporativo RC</li>
                        <li>Dirección General de Juegos y Sorteos</li>
                    </ul>
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                    Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en adelante “Derechos ARCO”), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Datos Personales de “ControlES” a la dirección legal@corporativorc.com. Tenga presente que para ciertos fines, la revocación de su consentimiento imposibilitará a “ControlES” seguir prestando el servicio que usted solicitó.
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                    Con la finalidad de dar seguimiento a la solicitud, usted o su representante legal, deberá acreditar correctamente su identidad, por lo que es necesario que complete todos los campos indicados en el formulario y adjunte copia de una de las identificaciones oficiales vigentes especificadas en el documento.
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                    Una vez presentada su solicitud, “ControlES” podrá solicitarle a usted o su representante legal, en un periodo no mayor a 7 días hábiles, la información y/o documentación necesaria para el seguimiento a la solicitud de acuerdo a los términos que marca la legislación, por lo que el solicitante contará con 10 días hábiles posteriores a su recepción para atender este requerimiento. De lo contrario la solicitud se tendrá por no presentada.
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                    Asimismo, en un plazo posterior de 20 días hábiles “ControlES” emitirá una resolución, la cual le será notificada por los medios de contacto que haya establecido en la solicitud. Una vez emitida la resolución y en caso de que la misma sea procedente (parcial o totalmente), “ControlES” contará con 7 días hábiles para adoptar dicha resolución.
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                    Los términos y plazos indicados en los párrafos anteriores, podrán ser ampliados una sola vez en caso de ser necesario y se le deberá notificar a través de los medios de contacto que haya especificado en el formulario.
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                    La revocación y el ejercicio de los Derechos ARCO serán gratuitos, debiendo el solicitante cubrir únicamente los gastos justificados de envío, o el costo de reproducción en copias u otros formatos establecidos en la solicitud.
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                    Los datos de contacto del departamento que dará trámite a las solicitudes para el ejercicio de los derechos ARCO y atenderá cualquier duda que pudiera surgir respecto al tratamiento de su información son los siguientes:
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                    “ControlES” se reserva el derecho de, modificar, agregar o eliminar partes del presente aviso de privacidad en cualquier momento, en el entendido de que toda modificación al mismo se publicará en el sitio web www.cargaygana.com.mx, indicando la fecha de la última versión del aviso. Visite habitualmente esta página web para informarse si ha ocurrido algún cambio al presente.
                </Typography>
                <br/>
                <Footer />
                <br />
            </Container>
        </React.Fragment>
    );
}

export default Privacy;