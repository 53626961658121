import React, { useEffect, useState } from 'react';
import axios from 'axios';
// Start Material-ui Imports Styles
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
// End Material-ui Imports Styles
// Start Material-ui Imports Icons
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// End Material-ui Imports Icons

// Start On page only Stlyles
const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      width: '100%',
    },
    paper: {
        flexGrow: 1,
        padding: theme.spacing(4),
        margin: theme.spacing(.2)
    },
    fab: {
        marginTop: theme.spacing(-1),
        marginRight: theme.spacing(1),
        float: "right"
    },
    seccion: {
        padding: theme.spacing(2)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        padding: theme.spacing(3),
    },
    paragraph: {
        display: 'inline-block'
    }
}));
// End On page only Stlyles

//Main Class (functional class)
const Address = () => {
    //Create Instance for classes
    const classes = useStyles();
    //Create Instance for theme
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    // Start States
    //State for handle Dialog
    const [open, setOpen] = useState(false);
    //States for address form
    const [street, setStreet] = useState('');
    const [ext, setExt] = useState('');
    const [inter, setInter] = useState('');
    const [neighborhood, setNeighborhood] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    //States for handle address form errors
    const [eStreet, setEStreet] = useState(false);
    const [eExt, setEExt] = useState(false);
    const [ePostalcode, setEPostalcode] = useState(false);
    const [eNeighborhood, setENeighborhood] = useState(false);
    const [eCity, setECity] = useState(false);
    const [eState, setEState] = useState(false);
    const [eCountry, setECountry] = useState(false);
    //States for something
    const [addresses, setAddresses] = useState([]);
    const [updId, setUpdId] = useState(null);

    /********
     * function that is executed as soon as this component is mounted
    ********/
    useEffect(() => {
        getAddresses();
    },[]);

    /********
     * function that gets all the data about addresses related with profile
    ********/
    const getAddresses = () => {
        axios.post(`${process.env.REACT_APP_APIUSER_CRUD}/getAddresses`, null, {
            headers: { token: localStorage.getItem('token') }
        }).then(response => {
            if (response.data.length > 0) {
                setAddresses(response.data);
            } else {
                handleOpen()
            }
        });
    }
    /********
     * function to handle opener of Dialog and checks if there are data to update or create a new address
    ********/
    const handleOpen = (index = null) => {
        if(Number.isInteger(index)) {
            setStreet(addresses[index].street);
            setExt(addresses[index].ext);
            setInter(addresses[index].inter);
            setNeighborhood(addresses[index].neighborhood);
            setZipcode(addresses[index].zipcode);
            setCity(addresses[index].city);
            setState(addresses[index].state);
            setCountry(addresses[index].country);
            setUpdId(addresses[index].id);
        } else {
            setUpdId(null);
        }
        setOpen(true);
    }

    /********
     * function to handle clouser of Dialog that return all states to default value
    ********/
    const handleClose = () => {
        setOpen(false);
        setStreet('');
        setExt('');
        setInter('');
        setNeighborhood('');
        setZipcode('');
        setCity('');
        setState('');
        setCountry('');
        setEStreet(false);
        setEExt(false);
        setEPostalcode(false);
        setENeighborhood(false);
        setECity(false);
        setEState(false);
        setECountry(false);
    };

    const handleAddress = () => {
        // Start statements to identify an invalid field
        if(!street || street === "") { setEStreet(true) } else { setEStreet(false) };
        if(!ext || ext === "") { setEExt(true) } else { setEExt(false) };
        if(!neighborhood || neighborhood === "") { setENeighborhood(true) } else { setENeighborhood(false) };
        if(!(/^\d{5}-\d{4}|\d{5}|[A-Z]\d[A-Z] \d[A-Z]\d$/.test(zipcode))) { setEPostalcode(true) } else { setEPostalcode(false) };
        if(!city || city === "") { setECity(true) } else { setECity(false) };
        if(!state || state === "") { setEState(true) } else { setEState(false) };
        if(!country || country === "") { setECountry(true) } else { setECountry(false) };
        // End statements to identify an invalid field

        // statement to know if send or not a call to API
        if(!street || street === "" || !ext || ext === "" || !neighborhood || neighborhood === "" || !(/^\d{5}-\d{4}|\d{5}|[A-Z]\d[A-Z] \d[A-Z]\d$/.test(zipcode)) ||
        !city || city === "" || !state || state === "" || !country || country === "") {
            return null;
        } else {
            // statement to know 
            if(updId) {
                //API call to update address
                axios.post(`${process.env.REACT_APP_APIUSER_CRUD}/updateAddress`, {
                    id: updId,
                    street: street,
                    ext: ext,
                    inter: inter,
                    neighborhood: neighborhood,
                    zipcode: zipcode,
                    city: city,
                    state: state,
                    country: country
                }, {
                    headers: { token: localStorage.getItem('token') }
                }).then(response => {
                    alert(response.data);
                    setOpen(false);
                    getAddresses();
                }).catch(err => console.error(err));
            } else {
                //API call to create a new address
                axios.post(`${process.env.REACT_APP_APIUSER_CRUD}/newAddress`, {
                    street: street,
                    ext: ext,
                    inter: inter,
                    neighborhood: neighborhood,
                    zipcode: zipcode,
                    city: city,
                    state: state,
                    country: country
                }, {
                    headers: { token: localStorage.getItem('token') }
                }).then(response => {
                    alert(response.data);
                    setOpen(false);
                    getAddresses();
                }).catch(err => console.error(err))
            }
        }
    }

    /********
     * function to delete selected address
    ********/
    const deleteAddress = id => {
        //API call to delete address
        axios.post(`${process.env.REACT_APP_APIUSER_CRUD}/deleteAddress`, {
            id: id
        }, {
            headers: { token: localStorage.getItem('token') }
        }).then(response => {
            alert(response.data);
            if(response.data === 'Eliminado') {
                getAddresses();
            }
        }).catch(err => console.error(err));
    }
    
    //HTML code to render
    return (
        <div>
            <div>
            <Paper className={classes.root}>
                <div className={classes.seccion}>
                    <Typography variant='h6'>Dirección</Typography>
                </div>
                <Divider/>
                <div className={classes.seccion}>
                    {addresses.map((address, i) => {
                        return(
                            <Paper className={classes.paper} elevation={0} variant="outlined" key={i}>
                                <b>{i+1}.-&emsp;</b>
                                <p className={classes.paragraph}>{address.street} #{address.ext} {address.inter ? `- ${address.inter}` : null }</p>
                                <Fab size="small" color="secondary" aria-label="delete" className={classes.fab} onClick={() => deleteAddress(address.id)}><DeleteForeverIcon /></Fab>
                                <Fab size="small" color="primary" aria-label="edit" className={classes.fab} onClick={() => { handleOpen(i)} }><EditIcon /></Fab>
                            </Paper>
                        )
                    })}
                    
                </div>
                <Divider/>
                {addresses.length < 2 ?
                <div className={classes.seccion}>
                    <Button variant="contained" color="primary" onClick={() => handleOpen()}>Agregar dirección</Button>
                </div> : null}
            </Paper>
        </div>
        {addresses.length < 2 ?
        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">{"Formulario de dirección"}</DialogTitle>
            <DialogContent>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="street"
                                name="street"
                                variant="outlined"
                                required
                                fullWidth
                                id="street"
                                label="Calle"
                                autoFocus
                                value={street}
                                onChange={e => setStreet(e.target.value)}
                                error={eStreet}
                                helperText={eStreet ? 'El campo no puede estár vacío' : null}
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                autoComplete="int"
                                name="int"
                                variant="outlined"
                                fullWidth
                                id="int"
                                label="Int."
                                value={inter}
                                onChange={e => setInter(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                autoComplete="ext"
                                name="ext"
                                variant="outlined"
                                required
                                fullWidth
                                id="ext"
                                label="Ext."
                                value={ext}
                                onChange={e => setExt(e.target.value)}
                                error={eExt}
                                helperText={eExt ? 'El campo no puede estár vacío, use S/N' : null}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                autoComplete="postalcode"
                                name="postalcode"
                                variant="outlined"
                                required
                                fullWidth
                                id="postalcode"
                                label="Codigo Postal"
                                value={zipcode}
                                onChange={e => setZipcode(e.target.value)}
                                error={ePostalcode}
                                helperText={ePostalcode ? 'Código postal incorrecto' : null}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="neighborhood"
                                name="neighborhood"
                                variant="outlined"
                                required
                                fullWidth
                                id="neighborhood"
                                label="Colonia"
                                value={neighborhood}
                                onChange={e => setNeighborhood(e.target.value)}
                                error={eNeighborhood}
                                helperText={eNeighborhood ? 'El campo no puede estár vacío' : null}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="city"
                                name="city"
                                variant="outlined"
                                required
                                fullWidth
                                id="city"
                                label="Ciudad"
                                autoFocus
                                value={city}
                                onChange={e => setCity(e.target.value)}
                                error={eCity}
                                helperText={eCity ? 'El campo no puede estár vacío' : null}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="state"
                                name="state"
                                variant="outlined"
                                required
                                fullWidth
                                id="state"
                                label="Estado"
                                autoFocus
                                value={state}
                                onChange={e => setState(e.target.value)}
                                error={eState}
                                helperText={eState ? 'El campo no puede estár vacío' : null}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="country"
                                name="country"
                                variant="outlined"
                                required
                                fullWidth
                                id="country"
                                label="Pais"
                                autoFocus
                                value={country}
                                onChange={e => setCountry(e.target.value)}
                                error={eCountry}
                                helperText={eCountry ? 'El campo no puede estár vacío' : null}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">Cancelar</Button>
                <Button onClick={handleAddress} color="primary" autoFocus>Guardar</Button>
            </DialogActions>
        </Dialog>:null}
      </div>
    );
}

export default Address;