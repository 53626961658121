import React, { useState } from 'react';
import axios from 'axios';
// Start Material-ui Imports Styles
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
// End Material-ui Imports Styles
// Start Material-ui Imports Icons
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
// End Material-ui Imports Icons

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignUp = ({isLogin}) => {
  const classes = useStyles();
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [pass1, setPass1] = useState('');
  const [pass2, setPass2] = useState('');
  const [eFname, setEFname] = useState(false);
  const [eLname, setELname] = useState(false);
  const [eEmail, setEEmail] = useState(false);
  const [ePhone, setEPhone] = useState(false);
  const [ePass1, setEPass1] = useState(false);
  const [ePass2, setEPass2] = useState(false);

  const printValues = e => {
    e.preventDefault();
    if(!fname || fname === "") { setEFname(true) } else { setEFname(false) };
    if(!lname || lname === "") { setELname(true) } else { setELname(false) };
    if(!(/^\w+(\.-?\w+)*@\w+(\.-?\w+)*(\.\w{2,3})+$/.test(email))) { setEEmail(true) } else { setEEmail(false) };
    if(!(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone))) { setEPhone(true) } else { setEPhone(false) };
    if(!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(pass1))) { setEPass1(true) } else { setEPass1(false) };
    if(pass1 !== pass2) { setEPass2(true) } else { setEPass2(false) };

    if(!fname || fname === "" || !lname || lname === "" || !(/^\w+(\.-?\w+)*@\w+(\.-?\w+)*(\.\w{2,3})+$/.test(email)) || !(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone)) || 
       !(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(pass1)) || pass1 !== pass2) {
         return null;
    } else {
      axios.post(`${process.env.REACT_APP_APIUSER_LOGIN}/newUser`, {
        firstname: fname,
        lastname: lname,
        email: email,
        phone: phone,
        password: pass1
      }).then(response => {
        console.log(response);
        if(response.data === "OK") {
          alert('Cuenta registrada exitosamente');
          isLogin(true);
        } else if(response.data === 'error: 1062') {
          alert('Error: ese correo ya ha sido registrado anteriormente');
        } else {
          alert('Error desconocido: favor de comunicarse con la estación');
        }
      })
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Registro
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="Nombre (s)"
                autoFocus
                value={fname}
                onChange={e => setFname(e.target.value)}
                error={eFname}
                helperText={eFname ? 'El campo no puede estár vacío' : null}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Apellidos"
                name="lastName"
                autoComplete="lname"
                value={lname}
                onChange={e => setLname(e.target.value)}
                error={eLname}
                helperText={eLname ? 'El campo no puede estár vacío' : null}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Correo"
                name="email"
                autoComplete="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                error={eEmail}
                helperText={eEmail ? 'Favor de poner un correo válido' : null}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="phonr"
                label="Telefóno"
                name="phone"
                autoComplete="phone"
                value={phone}
                onChange={e => setPhone(e.target.value)}
                error={ePhone}
                helperText={ePhone ? 'Favor de ingresar 10 numeros' : null}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password1"
                value={pass1}
                onChange={e => setPass1(e.target.value)}
                error={ePass1}
                helperText={ePass1 ? 'Mínimo 8 caracteres, al menos 1 mayúscula, 1 minúscula y 1 número' : null}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Verificar Contraseña"
                type="password"
                id="password2"
                value={pass2}
                onChange={e => setPass2(e.target.value)}
                error={ePass2}
                helperText={ePass2 ? 'Las contraseñas no coinciden' : null}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Al registrarte, se está aceptando los <Link href="/termsofservice">Terminos y Condiciones</Link> de esté servicio y su <Link href="privacypolitics">Politica de Privacidad</Link>.
              </Typography>
                
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={printValues}
          >
            Registrarse
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link variant="body2" onClick={() => isLogin(true)} style={{cursor: 'pointer'}}>
                ¿Ya tienes cuenta?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

export default SignUp;