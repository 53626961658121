import React from 'react';
// Start Material-ui Imports Styles
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
// End Material-ui Imports Styles

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://wp.corporativorc.com/">
                Sistemas de Ingeniería y Control
            </Link>{' 2019 - '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default Copyright;