import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
// Start Material-ui Imports Styles
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Container from '@material-ui/core/Container';
// End Material-ui Imports Styles
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const SignIn = ({isLogin}) => {
  const classes = useStyles();
  
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');
  const [recoveryMail, setRecoveryMail] = useState('');
  const [eUser, setEUser] = useState(false);
  const [ePass, setEPass] = useState(false);
  const [eRecoveryMail, setERecoveryMail] = useState(false);

  let history = useHistory();
  const handleLogIn = e => {
      e.preventDefault();
      if(!(/^\w+(\.-?\w+)*@\w+(\.-?\w+)*(\.\w{2,3})+$/.test(user))) { setEUser(true) } else { setEUser(false) };
      if(!pass || pass === "") { setEPass(true) } else { setEPass(false) };

      if(!(/^\w+(\.-?\w+)*@\w+(\.-?\w+)*(\.\w{2,3})+$/.test(user)) || !pass || pass === "") {
        return null;
      } else {
        axios.post(`${process.env.REACT_APP_APIUSER_LOGIN}`, {
          email: user,
          password: pass
        }).then(response => {
          if(response.data === false || response.data.error) {
            alert('Usuario y/o contraseña incorrecta');
            setUser('');
            setPass('');
          } else if(response.data.id) {
            axios.post(`${process.env.REACT_APP_APIUSER_LOGIN}/getToken`, {
              id:     response.data.id,
              name:   response.data.name,
              email: user
            }).then(response => {
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("crypto", '$2a$10$IGD0gs40BvUD0kkCxvZrOugKsUi5thBGWA.fQIVTbFKs5UPwCkEEK');
            }).then(() => {
              history.push('/mv');
            }).catch(err => console.error(err));
          }
        }).catch(err => console.error(err));
      }
  }

  const recoveryPass = e => {
    e.preventDefault();
    if(!(/^\w+(\.-?\w+)*@\w+(\.-?\w+)*(\.\w{2,3})+$/.test(recoveryMail))) { setERecoveryMail(true) } else { setERecoveryMail(false) };
    if(/^\w+(\.-?\w+)*@\w+(\.-?\w+)*(\.\w{2,3})+$/.test(recoveryMail)) {
      handleClose();
      axios.post(`${process.env.REACT_APP_APIUSER_PWD}/forgotten`, {
        email: recoveryMail
      }).then(response => {
        setRecoveryMail('');
        if(response.data.error) {
          alert(response.data.error);
        } else {
          alert('Favor de revisar tu correo');
        }
      }).catch(err => console.error(err));
    } else {
      return null;
    }
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Inicio
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={e => setUser(e.target.value)}
            value={user}
            error={eUser}
            helperText={eUser ? 'Correo no válido' : null}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={e => setPass(e.target.value)}
            value={pass}
            error={ePass}
            helperText={ePass ? 'Campo contraseña vacío' : null}
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleLogIn}
          >
            Entrar
          </Button>
          <Grid container>
            <Grid item xs>
              <Link variant="body2" onClick={handleOpen}  style={{cursor: 'pointer'}}>
                ¿Olvidaste tu contraseña?
              </Link>
            </Grid>
            <Grid item>
              <Link variant="body2" onClick={() => isLogin(false)} style={{cursor: 'pointer'}}>
                ¿No tienes cuenta? Registrate
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.modalPaper}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="emailForgotten"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={recoveryMail}
              onChange={e => setRecoveryMail(e.target.value)}
              error={eRecoveryMail}
              helperText={eRecoveryMail ? 'Fabor de ingresar un correo válido' : null}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={recoveryPass}
            >
              Enviar
            </Button>
          </div>
        </Fade>
      </Modal>
    </Container>
  );
}

export default SignIn;