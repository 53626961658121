import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import bcrypt from 'bcryptjs';

import Login from './views/Login';
import MView from './MView';
import Forbidden from './views/Forbidden';
import NoMatch from './views/NoMatch';
import Recovery from './views/Recovery';
import TyC from './views/Termsandcond';
import Privacy from './views/Privacy';

const App = () => {
  const clearStorage = () => {
    localStorage.clear();
  }

  let PrivateRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) => {
          return localStorage.getItem('token') && (bcrypt.compareSync('Crypt053cur3P4$$', localStorage.getItem('crypto'))) ? (
          // return localStorage.getItem('auth') ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/forbidden",
                state: { from: location }
              }}
            />
          )
          }
        }
      />
    );
  }

  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route path="/termsofservice">
            <TyC />
          </Route>
          <Route path="/privacypolitics">
            <Privacy />
          </Route>
          <Route path="/recoveryPass/:token">
            <Recovery />
          </Route>
          <PrivateRoute path="/mv">
            <MView clearStorage={clearStorage}/>
          </PrivateRoute>
          <Route path="/forbidden">
            <Forbidden />
          </Route>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
