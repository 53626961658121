import React, { useState } from 'react';
import axios from 'axios';
import { MobileView } from 'react-device-detect';
import QrReader from 'react-qr-reader'
import clsx from 'clsx';
// Start Material-ui Imports Styles
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
// End Material-ui Imports Styles
// Start Material-ui Imports Icons
import CropFreeIcon from '@material-ui/icons/CropFree';
// End Material-ui Imports Icons

const useStyles = makeStyles(theme => ({
    paper: {
        flexGrow: 1,
        padding: theme.spacing(2),
        minWidth: 150
    },
    button: {
        marginTop: theme.spacing(1)
    }
}));

const DashForm = ({setRaffle, getRaffles}) => {
    const classes = useStyles();
    const [scrstring, setScrstring] = useState('');
    const [camera, showCamera] = useState(false);

    const handleChange = event => {
        setScrstring(event.target.value);
    }

    const handleSubmut = () => {
        axios.post(`${process.env.REACT_APP_APIUSER_INDEX}/addTicket`, {
            str: scrstring
        },{
            headers: {
                token: localStorage.getItem('token'),
            }
        }).then(response => {
            setRaffle(response.data[0].idRaffle);
            getRaffles(response.data[0].idRaffle);
            setScrstring('');
        }).then(() => {
            alert('Boletos agregados con exito');
        }).catch(err => {
            alert('Codigo incorrecto o canjeado');
        });
    }

    const handleQrScan = data => {
        if (data) {
            setScrstring(data, () => alert('Hello'));
        }
    }
    
    const handleQrError = err => {
        console.error(err)
    };

    const handleClick = () => {
        if(!camera) {
            showCamera(true)
            setScrstring('');
        } else {
            showCamera(false)
        }
    }

    return (
        <Paper elevation={3} className={classes.paper}>
            <FormControl className={clsx(classes.margin, classes.textField)} fullWidth>
                <InputLabel htmlFor="standard-adornment">Ticket</InputLabel>
                <Input
                    id="standard-adornment"
                    type='text'
                    value={scrstring}
                    onChange={handleChange}
                    endAdornment={
                        <MobileView>
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle QRreader visibility"
                                onClick={handleClick}
                                >
                                    <CropFreeIcon />
                                </IconButton>
                            </InputAdornment>
                        </MobileView>
                    }
                />
                </FormControl>
            <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmut} disabled={scrstring.length >= 1?false:true} >Aceptar</Button>
            {camera ?
                <QrReader
                    delay={300}
                    onError={handleQrError}
                    onScan={handleQrScan}
                    style={{ width: '100%' }}
                /> : null
            }
        </Paper>
    );
}

export default DashForm;