import React, { useState } from 'react';

import Box from '@material-ui/core/Box';

import SingIn from '../components/SingIn';
import SignUp from '../components/SingUp';
import Copyright from '../components/Copyright';

const Login = () => {
    const [login, isLogin] = useState(true);

    return (
        <React.Fragment>
            {login ? <SingIn isLogin={isLogin} /> : <SignUp isLogin={isLogin} /> }
            <Box mt={8}>
                <Copyright />
            </Box>
        </React.Fragment>
    );
}

export default Login;