import React, { useState, useEffect } from 'react';
import axios from 'axios';
// Start Material-ui Imports Styles
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// End Material-ui Imports Styles

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      width: '100%',
    },
    seccion: {
        padding: theme.spacing(2)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        padding: theme.spacing(3),
    },
  }));

const General = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [addresses, setAddresses] = useState([]);
    const [eFname, setEFname] = useState(false);
    const [eLname, setELname] = useState(false);
    const [eEmail, setEEmail] = useState(false);
    const [ePhone, setEPhone] = useState(false);

    useEffect(() => {getProfile()}, []);

    const getProfile = () => {
        axios.post(`${process.env.REACT_APP_APIUSER_CRUD}/getAddresses`, null, {
            headers: { token: localStorage.getItem('token') }
        }).then(response => {
            if (response.data.length > 0) {
                setAddresses(response.data);
            } else {
                setAddresses([{
                    id: 0,
                    street: 'Agrega una dirección'
                }]);
            }
            axios.post(`${process.env.REACT_APP_APIUSER_CRUD}/getProfile`, null, {
                headers: { token: localStorage.getItem('token') }
            }).then(response => {
                setFname(response.data[0].firstname);
                setLname(response.data[0].lastname);
                setEmail(response.data[0].email);
                setPhone(response.data[0].phone);
                if (response.data[0].idAddress) {
                    setAddress(response.data[0].idAddress);
                } else {
                    setAddress(0);
                }
                
            }).catch(err => console.log(err))
        }).catch(err => console.log(err))
    }

    const updateProfile = () => {
        if(!fname || fname === "") { setEFname(true) } else { setEFname(false) };
        if(!lname || lname === "") { setELname(true) } else { setELname(false) };
        if(!(/^\w+(\.-?\w+)*@\w+(\.-?\w+)*(\.\w{2,3})+$/.test(email))) { setEEmail(true) } else { setEEmail(false) };
        if(!(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone))) { setEPhone(true) } else { setEPhone(false) };

        if(!fname || fname === "" || !lname || lname === "" || !(/^\w+(\.-?\w+)*@\w+(\.-?\w+)*(\.\w{2,3})+$/.test(email)) || !(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone)))
        {
            return null;
        } else {
            axios.post(`${process.env.REACT_APP_APIUSER_CRUD}/updateProfile`, {
                idAddress: (address !== 0) ? address : null,
                firstname: fname,
                lastname: lname,
                email: email,
                phone: phone
            }, {
                headers: { token: localStorage.getItem('token') }
            }).then(response => {
                alert(response.data);
            }).catch(err => console.error(err));
        }
    }

    const handleChange = event => {
        setAddress(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    return (
        <div>
            <Paper className={classes.root}>
                <div className={classes.seccion}>
                    <Typography variant='h6'>Perfil</Typography>
                </div>
                <Divider/>
                <div className={classes.seccion}>
                    <form className={classes.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    autoComplete="fname"
                                    name="firstName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="Nombre (s)"
                                    autoFocus
                                    onChange={e => setFname(e.target.value)}
                                    value={fname}
                                    error={eFname}
                                    helperText={eFname ? 'Campo nombre vacío' : null}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    autoComplete="lname"
                                    name="lastName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Apellidos"
                                    onChange={e => setLname(e.target.value)}
                                    value={lname}
                                    error={eLname}
                                    helperText={eLname ? 'Campo apellido vacío' : null}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    autoComplete="email"
                                    name="email"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Correo"
                                    onChange={e => setEmail(e.target.value)}
                                    value={email}
                                    error={eEmail}
                                    helperText={eEmail ? 'Correo inválido' : null}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    autoComplete="phone"
                                    name="phone"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="phone"
                                    label="Teléfono"
                                    onChange={e => setPhone(e.target.value)}
                                    value={phone}
                                    error={ePhone}
                                    helperText={ePhone ? 'Favor de ingresar su numero a 10 digitos' : null}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl className={classes.formControl} fullWidth>
                                    <InputLabel id="demo-controlled-open-select-label">&emsp;Dirección *</InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        id="demo-controlled-open-select"
                                        variant="outlined"
                                        open={open}
                                        onClose={handleClose}
                                        onOpen={handleOpen}
                                        value={address}
                                        onChange={handleChange}
                                    >
                                        {addresses.map((address, i) => {
                                            return(
                                                <MenuItem value={address.id} key={i}>{address.street}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Divider/>
                <div className={classes.seccion}>
                    <Button variant="contained" color="primary" disabled={address === 0 ? true : false} onClick={updateProfile}>Guardar cambios</Button>
                </div>
            </Paper>
        </div>
    );
}

export default General;