import React, { useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import DashCard from '../components/DashCard';
import DashForm from '../components/DashForm';
import Tables from '../components/Tables';
import Add from '../components/Add';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    }
}));

const Index = ({dataTickets, dataTickets2, raffle, setRaffle, getRaffles, name, bases}) => {
    const classes = useStyles();

    const [columns] = useState([
        { title: "id", field: "id", hidden: true },
        { title: "Ticket", field: "scrstring", defaultGroupOrder: 0 },
        { title: "Fecha", field: "date", grouping: false },
        { title: "Folio", field: "id", grouping: false }
    ]);

    return (
    <div className={classes.root}>
        <BrowserView>
            <Typography variant="h4">
                Bienvenido {name}
            </Typography>
        </BrowserView>
        <MobileView>
            <Typography variant="h6">
                Bienvenido {name}
            </Typography>
        </MobileView>
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <DashCard
                    title='Tickets registrados'
                    content={dataTickets.length}
                />
            </Grid>
            <Grid item xs={6}>
                <DashCard
                    title='Boletos participantes'
                    content={dataTickets.reduce((sum, num) => { return sum + num.tickets; }, 0)}
                />
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <DashForm setRaffle={setRaffle} getRaffles={getRaffles}/>
                <BrowserView>
                    <Hidden smDown>
                        <Add bases={bases}/>
                    </Hidden>
                </BrowserView>
            </Grid>
           <Grid item xs={12} md={8}>
                <Tables
                    title={raffle ? raffle.name : null}
                    columns={columns}
                    data={dataTickets.length !== 0 ? dataTickets2 : []}
                    options={{
                        //exportButton: true,
                        actionsColumnIndex: 3,
                    }}
                    localization={{
                        header: {
                            actions: 'Folios'
                        }
                    }}
                />
           </Grid>
        </Grid>
    </div>
    );
}

export default Index;