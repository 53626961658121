import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';

import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import VpnLockIcon from '@material-ui/icons/VpnLock';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));
  

const Recovery = () => {
    const classes = useStyles();
    let history = useHistory();
    let { token } = useParams();
    const [email1, setEmail1] = useState('');
    const [email2, setEmail2] = useState('');
    const [pass, setPass] = useState('');
    const [pass2, setPass2] = useState('');

    const [eEmail2, setEEmail2] = useState(false);
    const [ePass, setEPass] = useState(false);
    const [ePass2, setEPass2] = useState(false);

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_APIUSER_PWD}/identify`, {
            token: token
        }).then(response => {
            if(response.data.error) {
                alert(response.data.error);
                history.push('/');
            } else {
                setEmail1(response.data[0].email)
            }
        }).catch(err => console.error(err));
    },[history, token]);

    useEffect(() => {
        if(email1 !== email2) { setEEmail2(true) } else { setEEmail2(false) }
    }, [email1, email2, setEEmail2]);

    const handleSubmit = e => {
        e.preventDefault();
        if(!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(pass))) { setEPass(true) } else { setEPass(false) }
        if(pass !== pass2) { setEPass2(true) } else { setEPass2(false) }
        if(pass !== pass2 || !(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(pass))) {
            return null
        } else {
            axios.post(`${process.env.REACT_APP_APIUSER_PWD}/recover`, {
                token: token,
                email: email2,
                password: pass2
            }).then(response => {
                if(response.data.error) {
                    alert(response.data.error);
                } else {
                    alert('Contraseña restablecida');
                }
                history.push('/');
            })
        }
    }



    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <VpnLockIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Recuperar contraseña
                </Typography>
                <Typography component="h2" variant="subtitle2">
                <div>
                    {email1.slice(0,1)}
                    {email1.split('@')[0].split("").map((char, i) => {
                        if (i !== 0) {
                            return(
                                '*'
                            )
                        } else {
                            return null
                        }
                    })}@
                    {email1.split('@').pop().split('.')[0].slice(0,1)}
                    {email1.split('@').pop().split('.')[0].split("").map((char, i) => {
                        if (i !== 0) {
                            return(
                                '*'
                            )
                        } else {
                            return null
                        }
                    })}.
                    {email1.split('@').pop().split('.').pop().slice(0,1)}
                    {email1.split('@').pop().split('.').pop().split("").map((char, i) => {
                        if (i !== 0) {
                            return(
                                '*'
                            )
                        } else {
                            return null
                        }
                    })}
                </div>
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Correo"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={e => setEmail2(e.target.value)}
                        value={email2}
                        error={eEmail2}
                        helperText={eEmail2 ? 'Correo incorrecto' : null}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Nueva contraseña"
                        type="password"
                        id="password"
                        onChange={e => setPass(e.target.value)}
                        value={pass}
                        error={ePass}
                        helperText={ePass ? 'Mínimo 8 caracteres, al menos 1 mayúscula, 1 minúscula y 1 número' : null}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password2"
                        label="Verificar contraseña"
                        type="password"
                        id="password2"
                        onChange={e => setPass2(e.target.value)}
                        value={pass2}
                        error={ePass2}
                        helperText={ePass2 ? 'Las contraseñas no coinciden' : null}
                    />
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSubmit}
                        disabled={eEmail2}
                    >
                        Entrar
                    </Button>
                    <Grid container>
                        <Grid item>
                            <Link variant="body2" onClick={() => history.push('/')} style={{cursor: 'pointer'}}>
                                Regresar
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}

export default Recovery;