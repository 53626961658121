import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
// Start Material-ui Imports Styles
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// End Material-ui Imports Styles
// Start Material-ui Imports Icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
// End Material-ui Imports Icons

const Menu = ({setMenuTitle}) => {
    let { url } = useRouteMatch();

    return (
        <List>
            <ListItem button component={Link} to={url} onClick={() => {setMenuTitle('Inicio')}}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Inicio" />
            </ListItem>
            <ListItem button component={Link} to={`${url}/settings`} onClick={() => {setMenuTitle('Configuración')}}>
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Configuración" />
            </ListItem>
            {/* <ListItem button component={Link} to={`${url}/tickets`} onClick={() => {setMenuTitle('Boletos')}}>
                <ListItemIcon>
                    <ConfirmationNumberIcon />
                </ListItemIcon>
                <ListItemText primary="Boletos" />
            </ListItem> */}
        </List>
    );
}

export default Menu;