import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline, Typography, Button, List, ListItem, ListItemIcon, ListItemText, Link } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import myLogo from '../images/logo.png';
import Footer from '../components/Copyright';

const useStyles = makeStyles(theme => ({
    paragraph: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    space: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
    },
    bold: {
        fontWeight: "bold"
    },
    indentation: {
        paddingLeft: "30px"
    }
}));

const Termsandcond = () => {
    let history = useHistory();
    const classes = useStyles();
    return (
        <React.Fragment>
            <div style={{display: "flex", height: "10vh", minHeight: "50px", justifyContent: "space-between", padding: "1.5vh", maxWidth: "100%"}}>
                <div style={{display: "flex", justifyContent: "center", paddingLeft: "2vh"}}><img src={myLogo} style={{maxWidth: "300px", height: "auto"}}></img></div>
                <div style={{display: "flex", justifyContent: "center", paddingRight: "2vh"}}>
                    <Button onClick={() => {history.push('/privacypolitics')}}>Aviso de privacidad</Button>
                    <Button onClick={() => {history.push('/')}}>Inicio</Button>
                </div>
            </div>
            <hr />
            <Container component="main" maxWidth="lg">
                <CssBaseline />
                    <Typography variant="h2">Aviso de Privacidad</Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        Este contrato describe los términos y condiciones generales (en lo sucesivo únicamente "TÉRMINOS Y CONDICIONES") aplicables al uso de los contenidos, productos y servicios ofrecidos a través del sitio <strong><span>www.cargaygana.com.mx</span></strong> (en lo sucesivo, "SITIO WEB"), del cual es titular <strong><span>Sistemas de Ingeniería y Control RC SA de CV</span></strong> (en lo sucesivo, "ControlES"). Cualquier persona que desee acceder o hacer uso del sitio o los servicios que en él se ofrecen, podrá hacerlo sujetándose a los presentes TÉRMINOS Y CONDICIONES, así como a políticas y principios incorporados al presente documento. En todo caso, cualquier persona que no acepte los presentes términos y condiciones, deberá abstenerse de utilizar el SITIO WEB y/o adquirir los productos y servicios que en su caso sean ofrecidos.
                    </Typography>
                    <Typography variant="subtitle1" className={classes.bold}>I. DEL OBJETO.</Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        El objeto de los presentes TÉRMINOS Y CONDICIONES es regular el acceso y la utilización del SITIO WEB, entendiendo por este cualquier tipo de contenido, producto o servicio que se encuentre a disposición del público en general dentro del dominio: <strong><span>www.cargaygana.com.mx</span></strong>
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        ControlES se reserva la facultad de modificar en cualquier momento y sin previo aviso, la presentación, los contenidos, la funcionalidad, los productos, los servicios, y la configuración que pudiera estar contenida en el SITIO WEB; en este sentido, el USUARIO reconoce y acepta que <strong><span>Sistemas de Ingeniería y Control RC SA de CV</span></strong> en cualquier momento podrá interrumpir, desactivar o cancelar cualquiera de los elementos que conforman el SITIO WEB o el acceso a los mismos. 
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        El acceso al SITIO WEB por parte del USUARIO tiene carácter libre y, por regla general es gratuito sin que el USUARIO tenga que proporcionar una contraprestación para poder disfrutar de ello, salvo en lo referente al costo de la conexión a internet suministrada por el proveedor de este tipo de servicios que hubiere contratado el mismo USUARIO.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        El acceso a parte de los contenidos y servicios del SITIO WEB podrá realizarse previa suscripción o registro previo del USUARIO.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        El SITIO WEB se encuentra dirigido exclusivamente a personas que cuenten con la mayoría de edad (mayores de 18 años); en este sentido,<strong> <span>Sistemas de Ingeniería y Control RC SA de CV</span></strong> declina cualquier responsabilidad por el incumplimiento de este requisito.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        El SITIO WEB está dirigido principalmente a USUARIOS residentes en la República Mexicana, por lo cual, <strong><span>Sistemas de Ingeniería y Control RC SA de CV</span></strong> no asegura que el SITIO WEB cumpla total o parcialmente con la legislación de otros países, de forma que, si el USUARIO reside o tiene su domicilio establecido en otro país y decide acceder o utilizar el SITIO WEB lo hará bajo su propia responsabilidad y deberá asegurarse de que tal acceso y navegación cumple con la legislación local que le es aplicable, no asumiendo <strong><span>Sistemas de Ingeniería y Control RC SA de CV</span></strong> ninguna responsabilidad que se pueda derivar de dicho acto.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        Se hace del conocimiento del USUARIO que ControlES podrá administrar o gestionar el SITIO WEB de manera directa o a través de un tercero, lo cual no modifica en ningún sentido lo establecido en los presentes TÉRMINOS Y CONDICIONES.
                    </Typography>
                    <Typography variant="subtitle1" className={classes.bold}>II. DEL USUARIO.</Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        El acceso o utilización del SITIO WEB,  confiere la condición de USUARIO del SITIO WEB, por lo que quedará sujeto a los presentes TÉRMINOS Y CONDICIONES, así como a sus ulteriores modificaciones, sin perjuicio de la aplicación de la legislación aplicable, por tanto, se tendrán por aceptados desde el momento en el que se accede al SITIO WEB. Dada la relevancia de lo anterior, se recomienda al USUARIO revisar las actualizaciones que se realicen a los presentes TÉRMINOS Y CONDICIONES.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        Es responsabilidad del USUARIO utilizar el SITIO WEB de acuerdo a la forma en la que fue diseñado; en este sentido, queda prohibida la utilización de cualquier tipo de software que automatice la interacción o descarga de los contenidos o servicios proporcionados a través del SITIO WEB. Además, el USUARIO se compromete a utilizar la información, contenidos o servicios ofrecidos a través del SITIO WEB de manera lícita, sin contravenir lo dispuesto en los presentes TÉRMINOS Y CONDICIONES, la moral o el orden público, y se abstendrá de realizar cualquier acto que pueda suponer una afectación a los derechos de terceros, o perjudique de algún modo el funcionamiento del SITIO WEB.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        Así mismo, el usuario se compromete a proporcionar información lícita y veraz en los formularios habilitados en el SITIO WEB, en los cuales el usuario tenga que proporcionar ciertos datos o información para el acceso a algún contenido, producto o servicio ofrecido por el propio SITIO WEB. En todo caso, el USUARIO notificará de forma inmediata a ControlES acerca de cualquier hecho que permita suponer el uso indebido de la información registrada en dichos formularios, tales como, robo, extravío, o acceso no autorizado a cuentas y/o contraseñas, con el fin de proceder a su inmediata cancelación.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        El sólo acceso al SITIO WEB no supone el establecimiento de ningún tipo de relación entre ControlES y el USUARIO.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        Al tratarse de un SITIO WEB dirigido exclusivamente a personas que cuenten con la mayoría de edad, el USUARIO manifiesta ser mayor de edad y disponer de la capacidad jurídica necesaria para sujetarse a los presentes TÉRMINOS Y CONDICIONES.
                    </Typography>
                    <Typography variant="subtitle1" className={classes.bold}>III. DEL ACCESO Y NAVEGACIÓN EN EL SITIO WEB.</Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        ControlES no garantiza de ningún modo la continuidad y disponibilidad de los contenidos, productos o servicios ofrecidos a través del SITIO WEB, no obstante, ControlES llevará a cabo las acciones que de acuerdo a sus posibilidades le permitan mantener el buen funcionamiento del SITO WEB, sin que esto suponga alguna responsabilidad de parte de <strong><span>Sistemas de Ingeniería y Control RC SA de CV</span></strong>.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        De igual forma <strong><span>Sistemas de Ingeniería y Control RC SA de CV</span></strong> no será responsable ni garantiza que el contenido o software al que pueda accederse a través del SITIO WEB, se encuentre libre de errores, software malicioso, o que pueda causar algún daño a nivel de software o hardware en el equipo a través del cual el USUARIO accede al SITIO WEB.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        ControlES tampoco se hace responsable de los daños que pudiesen ocasionarse por un uso inadecuado del SITIO WEB. En ningún caso <strong><span>Sistemas de Ingeniería y Control RC SA de CV</span></strong> será responsable por las pérdidas, daños o perjuicios de cualquier tipo que surjan por el sólo acceso o utilización del SITIO WEB.
                    </Typography>
                    <Typography variant="subtitle1" className={classes.bold}>IV. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS.</Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        De conformidad con lo establecido en la  Ley Federal de Protección de Datos Personales en Posesión de Particulares, ControlES se compromete a adoptar las medidas necesarias que estén a su alcance para asegurar la privacidad de los datos personales recabados de forma que se garantice su seguridad, se evite su alteración, pérdida o tratamiento no autorizado.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        Además, a efecto de dar cumplimiento a lo establecido en la  Ley Federal de Protección de Datos Personales en Posesión de Particulares, todo dato personal que sea recabado a través del SITIO WEB, será tratado de conformidad con los principios de licitud, calidad, finalidad, lealtad, y responsabilidad. Todo tratamiento de datos personales quedará sujeto al consentimiento de su titular. En todo caso, la utilización de datos financieros o patrimoniales, requerirán de autorización expresa de sus titulares, no obstante, esta podrá darse a través del propio SITIO WEB utilizando los mecanismos habilitados para tal efecto, y en todo caso se dará la mayor diligencia y cuidado a este tipo de datos. Lo mismo ocurrirá en el caso de datos personales sensibles, considerando por estos aquellos que debido a una utilización indebida puedan dar origen a discriminación o su divulgación conlleve un riesgo para el titular.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        En todo momento se procurará que los datos personales contenidos en las bases de datos o archivos que en su caso se utilicen, sean pertinentes, correctos y actualizados para los fines para los cuales fueron recabados.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        El tratamiento de datos personales se limitará al cumplimiento de las finalidades previstas en el <strong>Aviso de Privacidad</strong> el cual se encontrará disponible en la siguiente dirección electrónica:
                    </Typography>
                    <Typography variant="caption" className={classes.indentation}>
                        www.cargaygana.com.mx/privacypolitics
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        <strong><span>Sistemas de Ingeniería y Control RC SA de CV</span></strong> se reserva el derecho a modificar su Política de Privacidad, de acuerdo a sus necesidades o derivado de algún cambio en la legislación. El acceso o utilización del SITIO WEB después de dichos cambios, implicará la aceptación de estos cambios.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        Por otra parte, el acceso al SITIO WEB puede implicar la utilización de cookies, las cuales, son pequeñas cantidades de información que se almacenan en el navegador utilizado por el USUARIO. Las cookies facilitan la navegación, la hacen más amigable, y no dañan el dispositivo de navegación, para ello, pueden recabar información para ingresar al SITIO WEB, almacenar las preferencias del USUARIO, así como la interacción que este tenga con el SITIO WEB, como por ejemplo: la fecha y hora en la que se accede al SITIO WEB, el tiempo que se ha hecho uso de este, los sitios visitados antes y después del mismo, el número de páginas visitadas, la dirección IP de la cual accede el usuario, la frecuencia de visitas, etc.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        Este tipo de información será utilizada para mejorar el SITIO WEB, detectar errores, y posibles necesidades que el USUARIO pueda tener, lo anterior a efecto de ofrecer a los USUARIOS servicios y contenidos de mejor calidad. En todo caso, la información que se recopile será anónima y no se identificará a usuarios individuales.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        En caso de que el USUARIO no desee que se recopile este tipo de información deberá deshabilitar, rechazar, restringir y/o eliminar el uso de cookies en su navegador de internet. Los procedimientos para realizar estas acciones pueden diferir de un navegador a otro; en consecuencia, se sugiere revisar las instrucciones facilitadas por el desarrollador del navegador. En el supuesto de que rechace el uso de cookies (total o parcialmente) el USUARIO podrá continuar haciendo uso del SITIO WEB, aunque podrían quedar deshabilitadas algunas de las funciones del mismo.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        Es posible que en el futuro estas políticas respecto a las cookies cambien o se actualicen, por ello es recomendable revisar las actualizaciones que se realicen a los presentes TÉRMINOS Y CONDICIONES, con objetivo de estar adecuadamente informado sobre cómo y para qué utilizamos las cookies que se generan al ingresar o hacer uso del SITIO WEB.
                    </Typography>
                    <Typography variant="subtitle1" className={classes.bold}>V. POLÍTICA EN MATERIA DE PROPIEDAD INTELECTUAL E INDUSTRIAL.</Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        <strong><span>Sistemas de Ingeniería y Control RC SA de CV</span></strong> por sí o como parte cesionaria, es titular de todos los derechos de propiedad intelectual e industrial del SITIO WEB, entendiendo por este el código fuente que hace posible su funcionamiento así como las imágenes, archivos de audio o video, logotipos, marcas, combinaciones de colores, estructuras, diseños y demás elementos que lo distinguen. Serán, por consiguiente, protegidas por la legislación mexicana en materia de propiedad intelectual e industrial, así como por los tratados internacionales aplicables. Por consiguiente, queda expresamente prohibida la reproducción, distribución, o difusión de los contenidos del SITIO WEB, con fines comerciales, en cualquier soporte y por cualquier medio, sin la autorización de <strong><span>Sistemas de Ingeniería y Control RC SA de CV</span></strong>.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        El USUARIO se compromete a respetar los derechos de propiedad intelectual e industrial de ControlES. No obstante, además de poder visualizar los elementos del SITIO WEB podrá imprimirlos, copiarlos o almacenarlos, siempre y cuando sea exclusivamente para su uso estrictamente personal.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        Por otro lado, el USUARIO, se abstendrá de suprimir, alterar, o manipular cualquier elemento, archivo, o contenido, del SITIO WEB, y por ningún motivo realizará actos tendientes a vulnerar la seguridad, los archivos o bases de datos que se encuentren protegidos, ya sea a través de un acceso restringido mediante un usuario y contraseña, o porque no cuente con los permisos para visualizarlos, editarlos o manipularlos.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        En caso de que el USUARIO o algún tercero consideren que cualquiera de los contenidos del SITIO WEB suponga una violación de los derechos de protección de la propiedad industrial o intelectual, deberá comunicarlo inmediatamente a <strong><span>Sistemas de Ingeniería y Control RC SA de CV</span></strong> a través de los datos de contacto disponibles en el propio SITIO WEB y/o a través de los siguientes medios:
                    </Typography>
                    <List>
                        <Link href="tel:+523336192370">
                            <ListItem button>
                                <ListItemIcon>
                                    <PhoneIcon />
                                </ListItemIcon>
                                    <ListItemText primary="+52 (33) 3619 2370"/>
                            </ListItem>
                        </Link>
                        <Link href="https://wa.me/523314152966?text=Contacto%20aviso%20de%20privacidad%20sorteos%20www.cargaygana.com.mx">
                            <ListItem button>
                                <ListItemIcon>
                                    <WhatsAppIcon />
                                </ListItemIcon>
                                <ListItemText primary="+52 (33) 1415 2966"/>
                            </ListItem>
                        </Link>
                        <Link href="mailto:info@corporativorc.com">
                            <ListItem button>
                                <ListItemIcon>
                                    <AlternateEmailIcon />
                                </ListItemIcon>
                                    <ListItemText primary="info@corporativorc.com" />
                            </ListItem>
                        </Link>
                    </List>
                    <Typography variant="subtitle1" className={classes.bold}>VI. LEGISLACIÓN Y JURISDICCIÓN APLICABLE.</Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        <span>Sistemas de Ingeniería y Control RC SA de CV</span> se reserva la facultad de presentar las acciones civiles o penales que considere necesarias por la utilización indebida del SITIO WEB, sus contenidos, productos o servicios, o por el incumplimiento de los presentes TÉRMINOS Y CONDICIONES. 
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        La relación entre el USUARIO y <span>Sistemas de Ingeniería y Control RC SA de CV</span> se regirá por la legislación vigente en México, específicamente en  <span>Jalisco</span>. De surgir cualquier controversia en relación a la interpretación y/o a la aplicación de los presentes TÉRMINOS Y CONDICIONES, las partes se someterán a la jurisdicción ordinaria de los tribunales que correspondan conforme a derecho en el estado al que se hace referencia.
                    </Typography>
                    <br/>
                    <Footer />
                    <br />
            </Container>
        </React.Fragment>
    );
}

export default Termsandcond;